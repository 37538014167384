import React from 'react';
import Resizer from 'react-image-file-resizer';
import imageCompression from 'browser-image-compression';
import '../styles/stylesImageResizer.css';

interface Props {}

interface ImageFile extends File {
  preview: string;
}


interface TransformedImages {
  large: Blob | null;
  small: Blob | null;
}

interface State {
  image: ImageFile | null;
  fileName: string;
  transformedImages: TransformedImages;
}

class ImageTransformer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      image: null,
      fileName: '',
      transformedImages: {
        large: null,
        small: null,
      },
    };
  }

  handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      this.setState({ image: imageFile });
    }
  };

  handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ fileName: event.target.value });
  };

  resizeAndCompressImage = async () => {
    const { image } = this.state;

    if (image) {
      // Resize images
      const resizedLarge = await this.resizeImage(image, 2118, 1189);
      const resizedSmall = await this.resizeImage(image, 624, 350);

      // Get dimensions of resized images
      const dimensionsLarge = await this.getImageDimensions(resizedLarge);
      const dimensionsSmall = await this.getImageDimensions(resizedSmall);

      // Compress images
      const compressedLarge = await this.compressImage(resizedLarge, 350 * 1024, dimensionsLarge);
      const compressedSmall = await this.compressImage(resizedSmall, 60 * 1024, dimensionsSmall);

      this.setState({
        transformedImages: {
          large: compressedLarge,
          small: compressedSmall,
        },
      });
    }
  };

  resizeImage = (image: ImageFile, width: number, height: number): Promise<File> => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        image,
        width,
        height,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'file'
      );
    });
  };

  getImageDimensions = (image: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  };

  compressImage = async (image: File, maxSize: number, dimensions: { width: number; height: number }): Promise<Blob> => {
    const options = {
      maxSizeMB: maxSize / 1024 / 1024,
      maxWidthOrHeight: Math.max(dimensions.width, dimensions.height),
      useWebWorker: true,
    };
    return await imageCompression(image, options);
  };

  downloadImage = (blob: Blob, fileName: string) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  handleDownload = () => {
    const { fileName, transformedImages } = this.state;
    if (transformedImages.large) {
      this.downloadImage(transformedImages.large, `${fileName}.jpg`);
    }
    if (transformedImages.small) {
      this.downloadImage(transformedImages.small, `small-${fileName}.jpg`);
    }
  };

  render() {
    const { image, fileName,  } = this.state;

    return (
      <div className="containerResize">
        <div className="input-containerResize">
          <input type="file" accept="image/*" onChange={this.handleImageChange} />
          <input
            type="text"
            placeholder="Nom des fichiers"
            value={fileName}
            onChange={this.handleFileNameChange}
          />
        </div>
        <div className="button-containerResize">
          <button className="buttonResize" onClick={this.resizeAndCompressImage}>
            Transformer les images
          </button>
          <button className="buttonResize" onClick={this.handleDownload}>
            Télécharger les images
          </button>
        </div>
        {(this.state.transformedImages.small && this.state.transformedImages.large) && 
          <div className='imgInfo'>
            <div>
              Grande image: {(this.state.transformedImages.large?.size / 1024).toFixed(2)} KB
            </div>
            <div>
              Petite image: {(this.state.transformedImages.small?.size / 1024).toFixed(2)} KB
            </div>
          </div>
        }
        {image && <img src={image.preview} alt="Preview" style={{ width: '300px' }} />}
      </div>
    );
  }
}

export default ImageTransformer;
