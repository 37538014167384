import React from 'react';
import desc from '../assets/photosDescriptions.json';
import photoList from '../assets/photoList.json';
import { } from "@fortawesome/free-brands-svg-icons";
import { photo } from './types'

const photoDesc = JSON.parse(JSON.stringify(desc));

interface Props {
  handleDialog: (value: string) => void;
};

interface State {
  isOpen: boolean;
  dialogPhoto?: photo;
}

class Conception3d extends React.Component <Props> {
  state: State = {
    isOpen: false,
    dialogPhoto: undefined,
  };

  componentDidMount() {
    const self = this;
    window.addEventListener('keydown', function(event) {
      if (event.key === 'Escape' && self.state.isOpen) {
        self.handleShowDialog(undefined);
      }
    });
  }

  handleShowDialog = (dialogPhoto?: photo) => {
    if (!this.state.isOpen) {
      this.props.handleDialog("app-opacity");
    } else {
      this.props.handleDialog("app");
    }
    this.setState({ isOpen: !this.state.isOpen, dialogPhoto });
  };

  render () {
    const { isOpen, dialogPhoto } = this.state;
    const photosJson = photoList.conception3d;

    return (
      <div id="conception3d" className="conception-3d">
        <div className="section-title">
          <h2>CONCEPTION 3D</h2>
          <div className="picto-3d">
            <img src="./img/neonAssets/picto1.png" alt= "" width={24} />
          </div>
          <hr className="section-hr"/>
        </div>
        <div className="section-description">
          <p>
            Réalisation de plans techniques & rendus 3D
          </p>
        </div>
        <div>
          <div className="container">
            <div className="photos">
              { photosJson.map((photo, index) => {
                return (
                  <div className="image-div" onClick={(event: any) => this.handleShowDialog(photosJson[index])}>
                    <img src={photosJson[index].srcSmall} alt={photosJson[index].alt}/>
                    <svg className='filter-white' version="1.1" id="Layer_1" x="0px" y="0px" width="100.01px" height="100.0px" viewBox="0 0 122.881 122.88" enable-background="new 0 0 122.881 122.88"><g><path d="M56.573,4.868c0-0.655,0.132-1.283,0.37-1.859c0.249-0.6,0.61-1.137,1.056-1.583C58.879,0.545,60.097,0,61.44,0 c0.658,0,1.287,0.132,1.863,0.371c0.012,0.005,0.023,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.426,2.098,1.426,3.442c0,0.03-0.002,0.06-0.006,0.089v51.62l51.619,0c0.029-0.003,0.061-0.006,0.09-0.006 c0.656,0,1.285,0.132,1.861,0.371c0.014,0.005,0.025,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.428,2.098,1.428,3.441c0,0.654-0.133,1.283-0.371,1.859c-0.248,0.6-0.609,1.137-1.057,1.583 c-0.445,0.445-0.98,0.806-1.58,1.055v0.001c-0.576,0.238-1.205,0.37-1.861,0.37c-0.029,0-0.061-0.002-0.09-0.006l-51.619,0.001 v51.619c0.004,0.029,0.006,0.06,0.006,0.09c0,0.656-0.133,1.286-0.371,1.861c-0.006,0.014-0.012,0.025-0.018,0.037 c-0.248,0.584-0.602,1.107-1.037,1.543c-0.883,0.882-2.1,1.427-3.443,1.427c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.444-0.806-0.98-1.055-1.58h-0.001c-0.239-0.575-0.371-1.205-0.371-1.861 c0-0.03,0.002-0.061,0.006-0.09V66.303H4.958c-0.029,0.004-0.059,0.006-0.09,0.006c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.445-0.806-0.98-1.055-1.58H0.371C0.132,62.726,0,62.097,0,61.44 c0-0.655,0.132-1.283,0.371-1.859c0.249-0.6,0.61-1.137,1.056-1.583c0.881-0.881,2.098-1.426,3.442-1.426 c0.031,0,0.061,0.002,0.09,0.006l51.62,0l0-51.62C56.575,4.928,56.573,4.898,56.573,4.868L56.573,4.868z"/></g></svg>
                  </div>
                );
              }, this) }
            </div>
          </div>
        </div>
        { isOpen && (
          <dialog
            className="dialogConception3d"
            open
            onClick={(event) => this.handleShowDialog(undefined)}
          >
            { dialogPhoto && photoDesc[dialogPhoto.tag] && 
            <>
              <img src={dialogPhoto.src} alt="dialogPhoto.src"/>
            </>
            }
          </dialog>
          )
        }
      </div>
    )
  }
};

export default Conception3d;