import { Controller } from "./AutoAdress";

export function generateNEONLedCSV(controllers: Controller[]): string {
    let csvContent = "";

    let universe = 0;
    let xT = 10;
    let yF = 0;
    let xF = 10;
    let yT = -200;
    console.log(controllers)

    if (controllers.length === 0) {
        return '';
    }
    let ledCount = 0;
    for (let i = 0; i < controllers.length; i++) {
        universe = controllers[i].univerStart;
        let channel = 1;
        let nbSorties = 0;
        
        if (controllers[i].outputs.length > 0) {
            nbSorties = controllers[i].outputCount;
        }
        
        for (let j = 0; j < nbSorties; j++) {
            yF = 0;
            yT = 0;
            // eslint-disable-next-line no-loop-func
            controllers[i].outputs[j].ledType.forEach((led, ledIndex) => {
                if (!led) {
                    csvContent += '';
                } else if (led === 'barre1m'){
                    yT += 100;
                    let ledName = ledCount;
                    let ledType = 'BARRE NEON - 1M'
                    ledCount++;
                    console.log(i, j, ledIndex, '1M');
                    csvContent += `${ledType};${universe};${channel};${xF};${yF};${xT};${yT};15;CONTROLLEUR-${i}/C${i}-OUT-${j+1}/LED-${ledName}\n`;
                    yF += 100;
                    channel += 177;
                    if (channel > 512) {
                        universe++;
                        channel -= 512;
                    }
                } else if (led === 'barre2m') {
                    yT += 200;
                    let ledName = ledCount;
                    let ledType = 'BARRE NEON - 2M'
                    ledCount++;
                    console.log(i, j, ledIndex, '2M');
                    csvContent += `${ledType};${universe};${channel};${xF};${yF};${xT};${yT};15;CONTROLLEUR-${i}/C${i}-OUT-${j+1}/LED-${ledName}\n`;
                    yF += 200;
                    channel += 357;
                    if (channel > 512) {
                        universe++;
                        channel -= 512;
                    }
                }
            })
            // for (let k = 0; k < nbBarres; k++) {
            //     console.log(controllers[i].outputs[j].ledType)
            //     console.log(controllers[i].outputs[j].ledType[k])
            //     if (!controllers[i].outputs[j].ledType) {
            //         console.log('coucou')
            //         csvContent += '';
            //     } else if (controllers[i].outputs[j].ledType[k] === 'barre1m'){
            //         yT += 100;
            //         let ledName = ledCount;
            //         let ledType = 'BARRE NEON - 1M'
            //         ledCount++;
            //         console.log(i, j, k, '1M');
            //         csvContent += `${ledType};${universe};${channel};${xF};${yF};${xT};${yT};15;CONTROLLEUR-${i}/C${i}-OUT-${j+1}/LED-${ledName}\n`;
            //         yF += 100;
            //         channel += 177;
            //         if (channel > 512) {
            //             universe++;
            //             channel -= 512;
            //         }
            //     } else if (controllers[i].outputs[i].ledType[k] === 'barre2m') {
            //         yT += 200;
            //         let ledName = ledCount;
            //         let ledType = 'BARRE NEON - 2M'
            //         ledCount++;
            //         console.log(i, j, k, '2M');
            //         csvContent += `${ledType};${universe};${channel};${xF};${yF};${xT};${yT};15;CONTROLLEUR-${i}/C${i}-OUT-${j+1}/LED-${ledName}\n`;
            //         yF += 200;
            //         channel += 357;
            //         if (channel > 512) {
            //             universe++;
            //             channel -= 512;
            //         }
            //     }
            // }
            xF += 40;
            xT += 40;
        }
        xF += 120;
        xT += 120;
    }
    return `Fixture Definition Name;Start Universe;Start Channel;StartX;StartY;EndX;EndY;Width;Fixture Name\n${csvContent}`;
}