import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons"

import React from 'react';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

class Contact extends React.Component {
	render () {

  return (
    <div className="contact">
		  <div className="section-title">
        <h2>CONTACT</h2>
        <hr className="section-hr"/>
      </div>
      <div>
        <FontAwesomeIcon icon={faEnvelope} size="1x"/> contact@neon-live.fr
        <br />
        <FontAwesomeIcon icon={faPhoneAlt} size="1x"/> +33 6 32 63 23 30 / +33 6 01 45 16 44
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="social">
            <a href="https://www.facebook.com/NeonLiveFR" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="2x" color="white" className="contact-icon"/>
            </a>
            <a href="https://www.instagram.com/neonlivefr/" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" color="white" className="contact-icon"/>
            </a>
            <a href="https://www.youtube.com/channel/UCmjHVA2_EfYHG3aaBCPnIfw/featured" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="2x" color="white" className="contact-icon"/>
            </a>
          </div>
          <Link to="/legal-mentions">
            <p>Mentions légales</p>
          </Link>
        </div>
      </div>
      <div className="copyright">
        © 2024 NEON LIVE
      </div>
    </div>
    );
  }
};

export default Contact;
