import { useState } from 'react';
import Switch from 'react-switch';
import { CSVLink } from "react-csv";
import { generateNEONLedCSV } from './generateCSV';

interface Output {
	ledCount: number;
	ledType: string[];
}

export interface Controller {
	outputCount: number;
	outputs: Output[];
	univerStart: number
}

function AutoAdress() {
	const [controllers, setControllers] = useState<Controller[]>([]);
	const [collapsed, setCollapsed] = useState<boolean[]>([]);

	const handleControllerCountChange = (e: any) => {
		const count = e.target.value;
		if (count > controllers.length) {
			let newControllers = controllers;
			for (let i = controllers.length; i < count; i++) {
				newControllers.push({
					outputCount: 0,
					outputs: [],
					univerStart: (i) * 10
				});
			}
			setControllers(newControllers);
			setCollapsed((prevCollapsed) => [
				...prevCollapsed,
				...Array(count - controllers.length).fill(false)
			]);
		} else if (count < controllers.length) {
			setControllers(controllers.slice(0, count));
			setCollapsed(collapsed.slice(0, count));
		}
	};

	const handleOutputCountChange = (controllerIndex: number, e: any) => {
		const count = parseInt(e.target.value);
		const newControllers = [...controllers];
		if (count > newControllers[controllerIndex].outputCount) {
			for (let i = newControllers[controllerIndex].outputCount; i < count; i++) {
				newControllers[controllerIndex].outputs.push({
					ledCount: 0,
					ledType: []
				});
			}
			newControllers[controllerIndex].outputCount = count;
		} else if (count < newControllers[controllerIndex].outputCount) {
			newControllers[controllerIndex].outputs = newControllers[controllerIndex].outputs.slice(0, count);
			newControllers[controllerIndex].outputCount = count;
		}
		setControllers(newControllers);
	};

	const handleUniverStart = (controllerIndex: number, e: any) => {
		const univer = parseInt(e.target.value);
		let newControllers = [...controllers];
		newControllers[controllerIndex].univerStart = univer;
		setControllers(newControllers);
	};

	const handleAddLed = (controllerIndex: number, outputIndex: any, e: any) => {
		const newControllers = [...controllers];
		newControllers[controllerIndex].outputs[outputIndex].ledCount++;
		newControllers[controllerIndex].outputs[outputIndex].ledType.push("barre2m");
		setControllers(newControllers);
	};

	const handleRemoveLed = (controllerIndex: number, outputIndex: number, ledIndex: number) => {
		const newControllers = [...controllers];
		newControllers[controllerIndex].outputs[outputIndex].ledCount--;
		const halfStart = newControllers[controllerIndex].outputs[outputIndex].ledType.slice(0, ledIndex);
		const halfEnd = newControllers[controllerIndex].outputs[outputIndex].ledType.slice(ledIndex + 1);
		newControllers[controllerIndex].outputs[outputIndex].ledType = halfStart.concat(halfEnd);
		setControllers(newControllers);
	};

	const handleLedTypeChange = (controllerIndex: number, outputIndex: number, ledIndex: number, e: any) => {
		const type = e;
		const newControllers = [...controllers];
		newControllers[controllerIndex].outputs[outputIndex].ledType[ledIndex] = type ? 'barre1m' : 'barre2m';
		setControllers(newControllers);
	};

	const toggleCollapse = (index: number) => {
		const newCollapsed = [...collapsed];
		newCollapsed[index] = !newCollapsed[index];
		setCollapsed(newCollapsed);
	};

	return (
		<div className="auto-adress">
			<div className='setup'>
				<div className='setupHeader'>
					<label>
						<h1>
							Nombre de contrôleurs led:
						</h1>
						<input
							type="number"
							min="0"
							value={controllers.length}
							onChange={handleControllerCountChange}
						/>
					</label>
					<CSVLink data={generateNEONLedCSV(controllers)} filename='patchLed.csv'>
						<div className='csvExport'>
							Export CSV pour madmapper
						</div>
					</CSVLink>
				</div>
				{controllers.map((controller: Controller, controllerIndex: number) => (
					<div key={controllerIndex} className="controller-section">
						<h3 onClick={() => toggleCollapse(controllerIndex)}>
							Contrôleur {controllerIndex}
							<h6>({controller.outputCount} sorties)</h6>
							{collapsed[controllerIndex] ? '▲' : '▼'}
						</h3>
						{!collapsed[controllerIndex] && (
							<>
								<label className={(controllers[controllerIndex+1] && (controllers[controllerIndex+1].univerStart <= controller.univerStart)) ? 'inputWarning' : ''}>
									Univers de départ:
									<input
										type="number"
										min={0}
										value={controller.univerStart}
										onChange={(e) => handleUniverStart(controllerIndex, e)}
									/>
								</label>
								<label>
									Nombre de sorties:
									<input
										type="number"
										min="0"
										value={controller.outputCount}
										onChange={(e) => handleOutputCountChange(controllerIndex, e)}
									/>
								</label>
								{controller.outputs.map((output, outputIndex) => (
									<div key={outputIndex} className="output-section">
										<h4>Sortie {outputIndex + 1}</h4>
										<div className='ledGroup'>
											<div className='addLed' onClick={(e) => handleAddLed(controllerIndex, outputIndex, e)}>
												<svg version="1.1" id="Layer_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 122.881 122.88" enable-background="new 0 0 122.881 122.88"><g><path d="M56.573,4.868c0-0.655,0.132-1.283,0.37-1.859c0.249-0.6,0.61-1.137,1.056-1.583C58.879,0.545,60.097,0,61.44,0 c0.658,0,1.287,0.132,1.863,0.371c0.012,0.005,0.023,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.426,2.098,1.426,3.442c0,0.03-0.002,0.06-0.006,0.089v51.62l51.619,0c0.029-0.003,0.061-0.006,0.09-0.006 c0.656,0,1.285,0.132,1.861,0.371c0.014,0.005,0.025,0.011,0.037,0.017c0.584,0.248,1.107,0.603,1.543,1.039 c0.881,0.88,1.428,2.098,1.428,3.441c0,0.654-0.133,1.283-0.371,1.859c-0.248,0.6-0.609,1.137-1.057,1.583 c-0.445,0.445-0.98,0.806-1.58,1.055v0.001c-0.576,0.238-1.205,0.37-1.861,0.37c-0.029,0-0.061-0.002-0.09-0.006l-51.619,0.001 v51.619c0.004,0.029,0.006,0.06,0.006,0.09c0,0.656-0.133,1.286-0.371,1.861c-0.006,0.014-0.012,0.025-0.018,0.037 c-0.248,0.584-0.602,1.107-1.037,1.543c-0.883,0.882-2.1,1.427-3.443,1.427c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.444-0.806-0.98-1.055-1.58h-0.001c-0.239-0.575-0.371-1.205-0.371-1.861 c0-0.03,0.002-0.061,0.006-0.09V66.303H4.958c-0.029,0.004-0.059,0.006-0.09,0.006c-0.654,0-1.283-0.132-1.859-0.371 c-0.6-0.248-1.137-0.609-1.583-1.056c-0.445-0.445-0.806-0.98-1.055-1.58H0.371C0.132,62.726,0,62.097,0,61.44 c0-0.655,0.132-1.283,0.371-1.859c0.249-0.6,0.61-1.137,1.056-1.583c0.881-0.881,2.098-1.426,3.442-1.426 c0.031,0,0.061,0.002,0.09,0.006l51.62,0l0-51.62C56.575,4.928,56.573,4.898,56.573,4.868L56.573,4.868z" /></g></svg>
											</div>
											{output.ledType.map((led, ledIndex) => (
												<div key={ledIndex} className='ledGroupChild'>
													<div className={'setup' + led}>
														{ledIndex + 1}
													</div>
													<div className='removeBarre' onClick={() => handleRemoveLed(controllerIndex, outputIndex, ledIndex)}>
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
															<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
															<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
														</svg>
													</div>
													<Switch
														checkedIcon={false}
														width={25}
														height={12}
														handleDiameter={10}
														onHandleColor='#EEEEEE'
														offHandleColor='#EEEEEE'
														uncheckedIcon={true}
														onColor='#9ed2ff'
														offColor='#8282FF'
														onChange={(e) => handleLedTypeChange(controllerIndex, outputIndex, ledIndex, e)}
														checked={led === 'barre1m'} />
												</div>
											))}
										</div>
									</div>
								))}
							</>
						)}
					</div>
				))}
			</div>
			<div className='renduLedBorder'>
				<div className='renduLed'>
					{controllers.map((controller: { outputCount: number; outputs: Output[]; }, controllerIndex: number) => (
						<div key={controllerIndex} className="renduControlleur">
							{controller.outputs.map((output, outputIndex) => {
								return (
									<div className="renduSortie">
										{
											output.ledType.map((led, ledIndex) => (
												<div key={ledIndex} className={led}>
													{ledIndex + 1}
												</div>
											))
										}
									</div>
								)
							}
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default AutoAdress;
