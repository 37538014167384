import React from 'react';
import '../arrow.scss'

class Description extends React.Component {

  render () {
    return (
      <div id="description" className="description">
        <p>
          NEON est un collectif Français basé à Lyon composé d'artistes du numérique, de scénographes, d'électroniciens et de développeurs.
          <br />Né d'une envie d'expérimentation artistique, ce projet evolue aujourd'hui entre scénographie et arts numériques.
        </p>
      </div>
    );
  }
};

export default Description;