import React, { useRef } from 'react';
import '../arrow.scss'


export default function Header() {
  const windowWidth = useRef(window.innerWidth);
  
  function handleVideo(id: string) {
    var e = document.getElementById(id);
    if (e) {
      e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  console.log('width: ', windowWidth.current);

  return (
    <>
      {windowWidth.current > 1000 &&
        <div id="description" className="header-container">
          <div className='header-item' onClick={() => handleVideo('stageDesign')}>
            <p>
            STAGE DESIGN
            </p>
          </div>
          <div className="vl"></div>
          <div className='header-item' onClick={() => handleVideo('artsNumeriques')}>
            <p>
            ARTS NUMERIQUES
            </p>
          </div>
          <div className="vl"></div>
          <div className='header-item' onClick={() => handleVideo('tour')}>
            <p>
            ON TOUR
            </p>
          </div>
          <div className="vl"></div>
          <div className='header-item' onClick={() => handleVideo('video')}>
            <p>
              VIDEOS
            </p>
          </div>
          <div className="vl"></div>
          <div className='header-item' onClick={() => handleVideo('conception3d')}>
            <p>
            CONCEPTION 3D
            </p>
          </div>
        </div>
      }
    </>
  );
}
