import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import MentionsLegalesPage from './components/MentionsLegales';
import App from './App';
import AutoAdress from './components/AutoAdress';
import ImageResizer from './components/ImageResizer';

const AppTest = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/legal-mentions" element={<MentionsLegalesPage />} />
        <Route path="/auto-adress" element={<AutoAdress />} />
        <Route path="/image-resizer" element={<ImageResizer />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(<AppTest />, document.getElementById('root'));

reportWebVitals();
