import React from 'react';
import '../arrow.scss'

class Home extends React.Component {
  handleArrow() {
    var e = document.getElementById("description");
    if (e) {
      e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  render () {
    return (
      <div className="home">
        <img src="./img/neon-main-2.jpeg" alt="neon-tapage" className="home-image" />
        <div className="home-text">
          <img src="./img/neonAssets/neon-noir-baseline.png" alt="logo-neon" className="home-logo" />
        </div>
        <div className="arrow-content" onClick={this.handleArrow}>
          <svg id="more-arrows">
            <polygon className="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
            <polygon className="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
          </svg>
        </div>
      </div>
    );
  }
};

export default Home;