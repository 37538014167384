import React from 'react';
import ReactPlayer from "react-player"
import videoList from '../assets/videoList.json';

const videos = videoList.video;

class Video extends React.Component {
  render () {
    return (
      <div id="video" className="video">
        <div className="section-title">
          <h2>VIDEOS</h2>
          <div className="picto-v">
            <img src="./img/neonAssets/picto1.png" alt= "" width={24} />
          </div>
          <hr className="section-hr"/>
        </div>
        <div className="video-grid">
        { videos.map((v) => {
          return (
          <ReactPlayer
              className='react-player'
              url={v.url}
              alt={v.alt}
              controls={true}
            />);
        })}
        </div>
      </div>
    );
  }
};

export default Video;