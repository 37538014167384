import React from 'react';
import Logo from '../logo-white.png';
import Contact from './Contact';
import { Link } from 'react-router-dom';

class MentionsLegalesPage extends React.Component {
  render() {
    return (
	<div>
		<div className="mentions-legales-page">
			<div className="header">
				<Link to='/'>
					<img src={Logo} alt="Logo" className="logo"/>
				</Link>
			</div>
			<div className="content">
				<h1>Mentions Légales</h1>
				<p>Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du site internet <a href='neon-live.fr/'>neon-live.fr</a> sont :</p>
				<ul>
				<li>Association : NEON LIVE</li>
				<li>Siège social : 4 rue alexis perroncel, 69100 Villeurbanne</li>
				<li>Numéro de téléphone : 06.32.63.23.30</li>
				<li>Adresse e-mail : contact@neon-live.fr</li>
				<li>Hébergeur : OVH </li>
				</ul>
				<p>Le site est soumis au respect de la législation française, notamment en ce qui concerne les droits d'auteur et la propriété intellectuelle.</p>
				<h2>Droits d'auteur et photos de scénographies</h2>
				<p>L'intégralité du contenu sur ce site est protégé par le droit d'auteur. Elles sont la propriété de l'association NEON LIVE ou de tiers ayant autorisé l'utilisation de ces images sur le site.</p>
				<p>Toute reproduction, représentation, modification, distribution, adaptation, ou exploitation, sous quelque forme que ce soit, de tout ou partie de ces photos, sans l'autorisation préalable écrite de l'association NEON LIVE ou des détenteurs des droits, est strictement interdite.</p>
				<p>L'utilisation non autorisée de ces photos peut constituer une violation des droits d'auteur et entraîner des poursuites judiciaires.</p>
			</div>
		</div>
		<Contact></Contact>
	</div>
    );
  }
}

export default MentionsLegalesPage;