import React from 'react';
import './styles/styles.css';
import Home from './components/Home';
import StageDesign from './components/StageDesign';
import ArtsNumeriques from './components/ArtsNumeriques';
import Conception3d from './components/Conception3d';
import Video from './components/Video';
import Contact from './components/Contact';
import Description from './components/Description';
import Header from './components/Header';
import Tour from './components/Tour';

class App extends React.Component {
  state = {
    mainClass: "app",
  };

  handleDialog = (style: any) => {
    this.setState({ mainClass: style });
  }


  render () {
    return (
      <div className={this.state.mainClass}>
        <Home/>
        <Header/>
        <Description/>
        <StageDesign handleDialog={this.handleDialog}></StageDesign>
        <Tour handleDialog={this.handleDialog}></Tour>
        <ArtsNumeriques handleDialog={this.handleDialog}></ArtsNumeriques>
        <Video/>
        <Conception3d handleDialog={this.handleDialog}></Conception3d>
        <Contact/>
      </div>
    );
  }

}

export default App;
